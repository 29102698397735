@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

body{
    margin:0px;
    padding: 0px;
    background-color: #000000;
    font-family: 'Lato', sans-serif;
}
*{
    box-sizing: border-box;
}
ul{
    list-style: none;
}
a{
    text-decoration: none;
}
button{
    outline: none;
    border: none;
}
input{
    outline: none;
    border: none;
}
.logo img{
    width: 90px;
}
#main{
    width:100%;
    height:768px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('./images/banner.png');
    position: relative;
    z-index: 0;
    
}
#main::before{
    position: absolute;
    content: "";
    background: -moz-radial-gradient(center, ellipse cover, rgba(181, 8, 8, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(181, 8, 8, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
    background: radial-gradient(ellipse at center, rgba(181, 8, 8, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b50808', endColorstr='#99000000',GradientType=1 );
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.header-heading {
    text-align: center; /* Center text within header */
  }
  
  .header-heading h2 {
    font-size: 85px; /* Adjust as needed */
    color: #ffffff;
    margin-bottom: 0;
  }
  
  .header-heading h1 {
    margin-top: 0;
    font-size: 50px; /* Adjust as needed */
    color: #ffffff;
  }
  
  .header-heading p.details {
    font-size: 25px; /* Adjust as needed */
    color: #79dce9;
  }

nav{
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	width:100%;
	z-index: 1;
    background-color: transparent;
    transition: 0.6s ease-in-out;
}
nav.active{
	box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.157);
	background-color: #000000;
}
nav ul{
	display: flex;
}
.active{
    background-color: #FF1414;
    color:#ffffff;
	box-shadow: 5px 10px 30px rgba(198, 64, 64, 0.411);
	transition: all ease 0.2s;
}
.active:hover{
    background-color: #000000;
    color:#ffffff;
	box-shadow: 5px 10px 30px rgba(198, 64, 64, 0.411);
	transition: all ease 0.2s;
}
nav ul li a{
    font-family: 'Lato';
	height:40px;
	line-height: 43px;
	margin: 3px;
	padding: 0px 22px;
	display: flex;
	font-size: 0.9rem;
	text-transform: uppercase;
	font-weight: 500;
	color:#ffffff;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
}
nav ul li a:hover{
	background-color: #FF1414;
    color:#ffffff;
	box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
	transition: all ease 0.2s;
}
nav .menu-btn,
.menu-icon{
		display:none;
	}
.name{
    text-align: center;
	font-family: 'Montserrat';
	width: 900px;
	position: absolute;
    left: 50%;
	top: 55%;
	transform: translate(-50%,-55%);
}
.name span{
    color: #FF1414;
}
.name .details{
    font-size: 23px;
    color: #c5c5c5;
}
.name h2{
	font-family:'Lato';
	font-size: 2.7rem;
	margin:0px;
	letter-spacing: 2px;
	color:#ffffff;
}
.name h1{
	font-family:'Lato';
	font-size: 5rem;
	margin:0px;
	letter-spacing: 2px;
	color:#ffffff;
}
.header-btns{
    display: flex;
    margin-top: 40px;
    margin-left: 40%;
}
.header-btn{
	width:160px;
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: #FF1414;
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
    border-radius: 5px;
	color:#ffffff;
}
.header-btn:hover{
	background-color: transparent;
    transition: all ease 0.5s;
    color: #ffffff;
    border: 2px solid #FF1414;
}

#features{
    width:100%;
    height:100vh;
    box-sizing: border-box;
    font-family:'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1px ;
    background-color: #000000;
}
#features h1{
    color: #fff;
    font-size: 3rem;
}
.a-container{
    display: flex;
    justify-content: center;
    align-content: center;


}
.a-box{
    background-color: #000000;
    width:300px;
    height: 450px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
}
.a-b-img{
    width: 100%;
    height: 60%;

}
.a-b-img img{
    padding: 15px;
    margin-top: 40px;
    width:100%;
    height: 50%;

}
.a-box:hover{
    border: 1px solid #FF1414;
    box-shadow: 2px 2px 12px rgba(184, 0, 0, 0.445);
}
.a-b-text{
    width: 100%;
    height: 100%;
    background-color:#d7e2e0;
    display:flex;
    justify-content: top;
    align-items: center;
    text-align: center;
    flex-direction: column;
    border-color: #FF1414;
}
.a-b-text h2{
    color: #000000;
}
.a-b-text p{
    margin: 0px;
    color:#000000a6;
    font-size: 1.1rem;
    font-family:'Lato';
    display: block;
    max-width: 90%;
    margin-top: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}
.a-b-text p:hover{
    color:#000000;
}
.a-b-text a{
    margin-top:1px ;
}
#presentaion{
    padding-top: 30px;
    width:100%;
    height:768px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(images/offer.png);
    text-align: center;
}

.pr-heading{
    text-align: center;
	font-family:'Lato';
	width: 600px;
	position: absolute;
    left: 50%;
	top: 55%;
	transform: translate(-50%,-55%);
}
.pr-heading span{
    color: #FF1414;
}
.pr-heading .details{
    font-size: 23px;
    color: #c5c5c5;
}
.pr-heading .details{
    font-size: 1.2rem;
}
.pr-heading h1{
	font-family:'Lato';
	font-size: 4rem;
	margin:0px;
	letter-spacing: 2px;
	color:#ffffff;
}
.pr-btns{
    display: flex;
    margin-top: 40px;
    margin-left: 35%;
}
.pr-btn{
	width:160px;
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: #FF1414;
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
    border-radius: 5px;
	color:#ffffff;
}
.pr-btn:hover{
	background-color: transparent;
    transition: all ease 0.5s;
    color: #ffffff;
    border: 2px solid #FF1414;
}

#about{
    margin-top:10px ;
	width:100%;
	height: 60vh;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 50px 5% 0px 5%;
	position: relative;
}

.about-text{
    width: 85%;
    color: #fff;
    list-style: circle;
}
.about-text h1{
	font-size: 3rem;
	color:#FF1414;
    font-weight: 500;
    margin: 0px;
	padding: 0px;
}
.about-text p{
    width: 150%;
	font-size: 1.2rem;
	color:#cecece;
	
}
.about-text button{
    margin-top: 20px;
	width: 140px;
	height: 45px;
	border-radius: 10px;
	border: none;
    outline: none;
    color: #ffffff;
	background-color:#FF1414;
}
.about-text button:hover{
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
	background-color: rgb(0, 0, 0);
    transition: all ease 0.3s;
    color: #ffffff;
}
.about-image{
    width: 50%;
}
.about-image img{
  width: 350px;
  border-radius: 3%;
}

#contact{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#contact h1{
    color: #fff;
    font-size: 3rem;
    padding-top: 2.5rem;
}
#contact form{
    width:600px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
#contact form input,
#contact form textarea{
    width:100%;
    height:50px;
    margin:5px 0px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: #ffffff2d;
    color: #ffffff;
    border-radius: 5px;
}
#contact form textarea{
    height: 150px;
}
#contact form input[type="submit"]{
    height: 45px;
    background: linear-gradient(90deg, #FF1414 20%,#f70000);
    color: #ffffff;
    text-transform: uppercase;
}

.clicked {
    background-color: #007bff; /* Change to whatever color you want */
    color: #fff; /* Change to the text color you prefer */
    cursor:not-allowed; /* Optionally change cursor style */
  }

#aboutus{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.aboutusp{
    margin: 4rem 6rem;
}

.aboutusp h1{
    font-size: 3rem;
    color: #ffffff;
    text-align: center;
}
.aboutusp h2{
    color: #ffffff;
    text-align: center;
}

.aboutusp p{
    width: 100%;
    font-size: 1.2rem;
    color: #ffffff;
    
}

#portfolioid{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.portfolio{
    margin: 4rem 6rem;
}

.portfolio h1{
    font-size: 3rem;
    color: #ffffff;
    text-align: center;
}

.portfolio p{
    width: 150%;
    font-size: 1.2rem;
    color: #ffffff;
    
}

.first-des{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}
.first-des-rev{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}
.des-text{
    width: 85%;
    text-align: start;
    font-size: 1.1rem;
    list-style: circle;
}

.des-text p{
    width: 95%;
    text-align: start;
    font-size: 1.1rem;
    list-style: circle;
    text-align: justify;
}

.des-text h2{
    padding-bottom: 1rem;
    font-size: 3rem;
	color:#FF1414;
    font-weight: 500;
    margin: 0px;
	padding: 0px;
}

.prot-img{
    position: relative;
    width:50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
}

.prot-img img{
    width: 80%;
    height: 250px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
}

.footercss{
    padding:0rem 2rem;
    color: #000000;
    background-color: #e6c4c4;
}
.bottom {
    padding-top: 0rem;
    text-align: start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.bottom div{
    display: flex;
    flex-direction: column;
    padding:0;
}

.bottom h4{
    font-size: 1rem;
    padding: 1rem 0 0.8rem 0;
}

.bottom a{
    text-decoration: none;
    color: #000000;
    padding-bottom: 0.4rem;
    font-size: 1.1rem;
}
.bottomf{
    background-color: #000000;
    display: contents;
}

.bottomf p{
    text-align: center;
    color: #420000;
}


@media(max-width:620px){
    #contact form{
        width:90%;
    }
}

@media(max-width:1190px){
	#main{
		background-size: 1150px !important;
		
	}
	.name{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .name h2{
        font-size: 1.5rem;
    }
    .name h1{
        font-size: 3rem;
    }
    .name .details{
        font-size: 1rem;
    }
    .pr-heading{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .pr-heading h2{
        font-size: 1.5rem;
    }
    .pr-heading h1{
        font-size: 3rem;
    }
    .pr-heading .details{
        font-size: 1rem;
    }
	.about-image img{
		height:400px;
    }
    .aboutb-image img{
		height:400px;
    }
    .aboutc-image img{
		height:400px;
    }
    #features{
		height:auto;
    }
    .a-container{
		flex-wrap: wrap;
	}
    .a-box{
		flex-grow: 1;
	}
    .a-b-img img{
		object-fit:contain;
    }
    
    

}

@media(max-width:970px){
	#main{
		height: 650px;
	}
	.name{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
    }
    .pr-heading{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
	.about-image{
        display:none;
    }
    .aboutb-image{
        display:none;
    }
    .aboutc-image{
        display:none;
    }
    .header-btn{
        margin-left: 20%;
        margin-top: 20px;
    }
    .pr-btn{
        margin-left: 20%;
        margin-top: 20px;
    }
	#about{
		justify-content: center;
        padding-top: 0px;
        text-align: center;
        margin-top: 0px;
	}
	.about-text{
        width: 100%;
	}
	.about-text h1{
		font-size:2rem;
    }
    .about-text p{
        width: 100%;
    }

    #aboutb{
		justify-content: center;
        padding-top: 100px;
        text-align: center;
        margin-top: 0px;
	}
	.aboutb-text{
        width: 100%;
	}
	.aboutb-text h1{
		font-size:2rem;
    }
    .aboutb-text p{
        width: 100%;
    }
    #aboutc{
		justify-content: center;
        padding-top: 120px;
        text-align: center;
        margin-top: 60px;
	}
	.aboutc-text{
        width: 100%;
	}
	.aboutc-text h1{
		font-size:2rem;
    }
    .aboutc-text p{
        width: 100%;
    }
    #features{
        margin-bottom: 100px;
    }
}
@media(max-width:600px){
	.name{
		width:60%;
    }
    .pr-heading{
		width:60%;
    }
}

@media screen and (max-width:850px){
    .portfolio{
        margin: 4rem 2rem;
    }

    .aboutusp{
        margin: 4rem 2rem;
    }

    .first-des{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 1.5rem;
    }
    .first-des-rev{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 1.5rem;
    }
    .des-text h2{
        width: 100%;
        text-align: center;
        font-size: 30px;
    }

    .des-text p{
        width: 100%;
        text-align: center;
    }

    .aboutusp p{
        width: 100%;
        text-align: center;
    }
    .prot-img{
        width: 100%;
        margin: 1rem auto;
        text-align: center;
    }
    .prot-img img{
        height: 250px;
        width: 100%;
    }

    .footercss{
        padding: 4rem 2rem;
    }

    .bottom div{
        width: 50%;
    }

    .header-heading h2 {
        font-size: 60px; /* Adjust as needed */
        color: #ffffff;
        margin-bottom: 0;
      }

      
      .header-heading p.details {
        font-size: 20px; /* Adjust as needed */
        color: #79dce9;
      }

      .header-btn{
        margin: 0 auto;
    }

    #features h1{
        padding-top: 2rem;
    }

    #contact h1{
        padding-top: 2rem;
    }
}

@media(max-width:1100px){.menu-icon{
    display:block;}
        nav{
            justify-content: space-between;
            height: 65px;
            padding: 0px 30px;
        }
        .logo img{
            width:70px;
        }
        .header-btns{
            margin: 0;
        }
        .pr-btns{
            margin: 0;
        }
        .menu{
            display:none;
            position:absolute;
            top:65px;
            left:0px;
            background-color:#000000;
            border-bottom:4px solid #FF1414;
            width:100%;
            padding:0px;
            margin:0px;
        }
        .menu li{
            width:100%;
        }
        nav .menu li a{
            width:100%;
            height:40px;
            justify-content: center;
            align-items: center;
            margin:0px;
            padding: 25px;
            border:1px solid rgba(38,38,38,0.03);
        }
        nav .menu-icon{
            cursor:pointer;
            float:right;
            padding:28px 20px;
            position:relative;
            user-select: none;
        }
        nav .menu-icon .nav-icon{
            background-color:#ffffff;
            display:block;
            height:2px;
            position:relative;
            transition: background 0.2s ease-out;
            width:24px;
        }
        nav .menu-icon .nav-icon:before,
        nav .menu-icon .nav-icon:after{
            background:rgb(255, 255, 255);
            content:'';
            display:block;
            height:100%;
            position:absolute;
            transition:all ease-out 0.2s;
            width:100%;
        }
        nav .menu-icon .nav-icon:before{
            top:6px;
        }
        nav .menu-icon .nav-icon:after{
            top:-6px;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon{
            background:transparent;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
            transform: rotate(-45deg);
            top:0;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
            transform: rotate(45deg);
            top:0;
        }
        nav .menu-btn{
            display:none;
        }
        nav .menu-btn:checked ~ .menu{
            display:block;
        }}
        @media(max-width:400px){
            .a-box{
                width: 100%;
                height:400px;
            }
        }
    
        